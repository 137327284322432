import BaseApiService from './api'
import axios from "axios";

export default class OrderApiService extends BaseApiService {

    static _instance;
    static instance() {
        if (!this._instance)
            this._instance = new OrderApiService(new SingleLock())
        return this._instance;
    }

    constructor(lock) {
        super();
        if (!(lock instanceof SingleLock)) {
            throw new Error('Sorry but this is singleton')
        }
    }

    getOrders(wineId = undefined, filtersObj = {}) {
        let params = {
            from: 0,
            to: 1000,
            wineId: filtersObj.ghostWineId ? filtersObj.ghostWineId : null,
            vintageId: filtersObj.vintageId ? filtersObj.vintageId : null,
            countryId: filtersObj.countryId ? filtersObj.countryId : null,
            regionId: filtersObj.regionId ? filtersObj.regionId : null,
            appellationId: filtersObj.appellationId ? filtersObj.appellationId : null,
            producerId: filtersObj.ghostProducerId ? filtersObj.ghostProducerId : null,
            ColourId: filtersObj.ColourId ? filtersObj.ColourId : null,
            PendingOrderType: filtersObj.PendingOrderType ? filtersObj.PendingOrderType : null,
            PendingOrderStatus: filtersObj.PendingOrderStatus ? filtersObj.PendingOrderStatus : null,
            isEnPrimeur: filtersObj.isEnPrimeur ? filtersObj.isEnPrimeur : null
        };

        return this.execute('get', '/PendingOrders', undefined, undefined, undefined, false, params)
    }

    getCollections(lang) {
        let selectedLangID = 1;
        switch (lang) {
            case 'de':
                selectedLangID = 2;
                break;
            case 'es':
                selectedLangID = 3;
                break;
            case 'ru':
                selectedLangID = 4;
                break;
            case 'fr':
                selectedLangID = 7;
                break;
            case 'it':
                selectedLangID = 9;
                break;
        }
        return this.execute('get', '/CollectionOrder?languageId=' + selectedLangID, undefined, undefined, undefined, false, {})
    }

    getTopCollections(lang) {
        let selectedLangID = 1;
        switch (lang) {
            case 'de':
                selectedLangID = 2;
                break;
            case 'es':
                selectedLangID = 3;
                break;
            case 'ru':
                selectedLangID = 4;
                break;
            case 'fr':
                selectedLangID = 7;
                break;
            case 'it':
                selectedLangID = 9;
                break;
        }
        return this.execute('get', '/TopCollections?languageId=' + selectedLangID, undefined, undefined, undefined, false, {})
    }

    getCollectionDetails(id, lang) {
        let selectedLangID = 1;
        switch (lang) {
            case 'de':
                selectedLangID = 2;
                break;
            case 'es':
                selectedLangID = 3;
                break;
            case 'ru':
                selectedLangID = 4;
                break;
            case 'fr':
                selectedLangID = 7;
                break;
            case 'it':
                selectedLangID = 9;
                break;
        }
        return this.execute('get', '/CollectionOrder/Details?id=' + id + '&languageId=' + selectedLangID, undefined, undefined, undefined, false, {})
    }

    getDeliveries() {
        return this.execute('get', '/DeliveryOrders', undefined, undefined, undefined, false, {})
    }

    getDeliveryDetails(id) {
        return this.execute('get', '/DeliveryOrders/Details?Id=' + id, undefined, undefined, undefined, false, {})
    }

    getSaleOrders() {
        return this.execute('get', '/SaleInvoice/Summary', undefined, undefined, undefined, false, {})
    }

    getSaleOrderDetails(id) {
        return this.execute('get', '/SaleInvoice/Details?id=' + id, undefined, undefined, undefined, false, {})
    }

    async getSaleOrderPdf(id, userId) {
        if (process.client) {
            let accessToken = await localStorage.getItem('access_token');
            let headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            };
            axios
                .get(
                    `https://api.vindome.net/api/SaleInvoice/Pdf?id=${id}`,
                    {
                        headers: headers,
                        responseType: 'blob'
                    }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${userId}-SO-${id}.pdf`);
                document.body.appendChild(link);
                link.click();
            });
        }
    }

    getTradesHistory(data) {
        let params = {
            from: data.from,
            to: data.to,
            onlyFilled: true
        };

        return this.execute('get', '/PendingOrdersHistory/Summary', undefined, undefined, undefined, false, params)
    }

    getOrderHistory(orderId) {
        let params = {
            orderId: orderId
        };

        return this.execute('get', '/PendingOrdersHistory', undefined, undefined, undefined, false, params)
    }

    saveOrder(data) {
        return this.execute('put', '/PendingOrders', data)
    }

    cancelOrder(data) {
        return this.execute('delete', '/PendingOrders?orderId=' + data.orderId + '&browser=' + data.browser + '&device=' + data.device)
    }

    createOrder(data) {
        return this.execute('post', '/PendingOrders', data)
    }

    createMangopayCard(data) {
        return this.execute('post', '/Mangopay/CreatePayIn', data)
    }

    registerMangopayCard(data) {
        return this.execute('get', '/MangopayCard/RegisterCard', undefined, undefined, undefined, false, data)
    }

    checkReferralCode(params) {
        let _queryStr = '';

        params.UniqueCasesIds.forEach((el) => {
            _queryStr += '&UniqueCasesIds=' + el;
        });

        params.MatchIds.forEach((el) => {
            _queryStr += '&MatchIds=' + el;
        });

        return this.execute('get', '/PromoCode/CalculatePromoCodeLiveMarketFees?PromoCodeName=' + params.PromoCodeName + _queryStr)
    }

    buyCollection(data, forDelivery, promo, isGift, gift, savedCreditCard, psp, isGiftDelivery, browser, device, cardId, browserInfo) {
        let _payload = {};

        _payload.isDeliveryOrder = forDelivery;
        _payload.collections = data;
        _payload.promoCode = promo;
        _payload.isGift = isGift;
        _payload.gift = gift;
        _payload.shouldUseSavedCard = savedCreditCard;
        _payload.PspType = psp;
        _payload.isGiftDelivery = isGiftDelivery;
        _payload.browser = browser;
        _payload.device = device;
        _payload.cardId = cardId || null;
        _payload.browserInfo = browserInfo || null

        return this.execute('post', '/CollectionOrder', _payload)
    }

    createDelivery(data) {
        return this.execute('post', '/Delivery', data)
    }

    checkPayment(data) {
        if (data.isInvoice) {
            return this.execute('get', '/MonthlyInvoice/GetPaymentStatus?id=' + data.id, undefined, undefined, undefined, false, {})
        } else if (data.isBuyCollection) {
            return this.execute('get', '/CollectionOrder/GetOrderStatus?orderId=' + data.id, undefined, undefined, undefined, false, {})
        } else {
            return this.execute('get', '/PendingOrders/GetOrderStatus?orderId=' + data.id, undefined, undefined, undefined, false, {})
        }
    }

    cancelPayment() {
        return this.execute('put', '/Sogecommerce/CancelTransaction');
    }

    stripeProcessAccount() {
        return this.execute('GET', '/Stripe/processAccount');
    }

    activateGift(data) {
        let params = {};
        params.giftcode = data;

        return this.execute('post', '/Gift/Activate', params);
    }

    getSavedCreditCard() {
        return this.execute('get', '/Sogecommerce/HasUserSavedCard');
    }

    getPromoCodes() {
        return this.execute('get', '/PromoCode');
    }

    getSavedMangopayCreditCard() {
        return this.execute('get', '/MangopayCard/GetCards');
    }

    setPayPalSuccess(token, payerId) {
        return this.execute('get', '/PayPal/success?token=' + token + '&payerID=' + payerId);
    }

    setPayPalSuccessBid(token, payerId) {
        return this.execute('get', '/PayPal/authorize/success?token=' + token + '&payerID=' + payerId);
    }

    setPayPalFail(token) {
        return this.execute('get', '/PayPal/cancel?token=' + token);
    }

    getGiftFees(ids) {
        if (ids.length) {
            if (ids.length > 1) {
                let _queryStr = '';
                ids.forEach((el, index) => {
                    _queryStr += (index === 0 ? '?' : '&') + 'collectionIds=' + el;
                });

                return this.execute('get', '/Gift/Fees' + _queryStr);
            } else {
                return this.execute('get', '/Gift/Fees?collectionIds=' + ids[0]);
            }
        }
    }
}

class SingleLock { }
