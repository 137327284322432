<template>
    <v-dialog content-class="cart-error" v-model="showModal" max-width="450px">
        <v-card class="cart-error-modal">
            <div class="warning-icon"></div>
            <v-card-title>{{ $t("christmas-gift21") }}</v-card-title>

            <v-card-text v-if="isGift" class="cart-text">{{ $t("christmas-gift24") }}</v-card-text>
            <v-card-text v-else class="cart-text">{{ $t("christmas-gift22") }}</v-card-text>

            <div class="cart-btn" @click="closeModal">Close</div>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'CartErrorModal',
    data() {
        return {
            showModal: false,
            isGift: false
        }
    },
    methods: {
        closeModal() {
            this.showModal = false
        }
    },
    mounted() {
        this.$nuxt.$on('carterror', (isGift) => {
            this.showModal = true
            this.isGift = isGift
        })
    }
}
</script>

<style scoped>
    .cart-error-modal {
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .warning-icon {
        background: url('../../assets/cart.png') no-repeat center;
        width: 80px;
        height: 80px;
        margin-bottom: 24px;
    }
    .cart-text {
        color: rgba(0, 0, 0, 0.64);
        text-align: center;
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 24px;
    }
    .cart-btn {
        display: flex;
        width: 108px;
        padding: 8px 16px;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        border-radius: 8px !important;
        background: #004F7F;
        color: white;
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 166.667% */
    }
    .cart-btn:hover {
        cursor: pointer;
    }
</style>
<style>
.v-dialog.cart-error {
    border-radius: 16px;
    background: #F4F4F4;
}
.v-dialog > .v-card.cart-error-modal > .v-card__title {
    color: rgba(0, 0, 0, 0.88);
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0;
    margin-bottom: 8px;
}
.v-dialog > .v-card.cart-error-modal > .v-card__text {
    padding: 0;
}
</style>
