import BaseApiService from './api';

export default class MarketApiService extends BaseApiService {
    static _instance;

    static instance() {
        if (!this._instance)
            this._instance = new MarketApiService(new SingleLock());
        return this._instance;
    }

    constructor(lock) {
        super();
        if (lock instanceof SingleLock === false) {
            throw new Error('Sorry but this is singleton');
        }
    }

    getProducers() {
        return this.execute('get', '/Filters/GetAvailableWinesAndProducers', undefined, undefined, undefined, false, {})
    }

    getTopWines() {
        return this.execute('get', '/TopWines', undefined, undefined, undefined, false, {})
    }

    getTopEnWines() {
        return this.execute('get', '/TopEnprimeurs', undefined, undefined, undefined, false, {})
    }

    getWines(body) {
        return this.execute('post', '/Market', body);
    }
}

class SingleLock { }
