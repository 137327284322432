import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

var locale = window.location.pathname.replace(/^\/([^\/]+).*/i,'$1');

export default new VueI18n({
  locale: (locale.trim().length && locale !== "/" && locale.trim().length === 2) ? locale : 'en' ,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en'
});
