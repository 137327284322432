import axios from 'axios';
import qs from 'qs';

const client = axios.create({
    json: true
});

export default class BaseApiService {
    async execute(method, resource, data, pathPrefix = '/api', basePrefix = 'api', isQS = false, params) {
        if (process.client) {
            let accessToken = await localStorage.getItem('access_token');
            let headers = {};

            if ((resource === '/CollectionOrder' && method === 'get') || resource.indexOf('/CollectionOrder/Details?id=') > -1) {
                headers = {
                    'Content-Type': isQS ? 'application/x-www-form-urlencoded' : 'application/json',
                };
            } else {
                headers = {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': isQS ? 'application/x-www-form-urlencoded' : 'application/json'
                };
            }
            data = isQS ? qs.stringify(data) : data;
            return client({
                baseURL: `https://${basePrefix}.vindome.net`,
                method,
                url: `${pathPrefix}${resource}`,
                params,
                data,
                headers
            }).then(res => {
                return res.data;
            }).catch(error => {
                throw error.response;
            });
        }
    }
}
