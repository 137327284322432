import BaseApiService from './api';

export default class PortfolioApiService extends BaseApiService {
    static _instance;
    static instance() {
        if (!this._instance)
            this._instance = new PortfolioApiService(new SingleLock());
        return this._instance;
    }

    constructor(lock) {
        super();
        if (lock instanceof SingleLock === false) {
            throw new Error('Sorry but this is singleton')
        }
    }

    getPortfolio(params) {
        return this.execute('get', '/Portfolio/get', undefined, undefined, undefined, false, params)
    }

    getPortfolioSummary(params) {
        return this.execute('get', '/PortfolioPerformance/summary', undefined, undefined, undefined, false, params)
    }

    getNewPortfolioSummary(params) {
        return this.execute('get', '/Portfolio/SummarySimplified', undefined, undefined, undefined, false, params)
    }

    getSoldWinesSummary(params) {
        return this.execute('get', '/PortfolioPerformance/SoldWinesSummarySimplified', undefined, undefined, undefined, false, params)
    }

    getSoldWinesList(params) {
        return this.execute('get', '/PortfolioPerformance/SoldWinesSummary', undefined, undefined, undefined, false, params)
    }

    getUserSpecificTransactions(params) {
        return this.execute('get', '/PortfolioPerformance/GetUserSpecificTransactions', undefined, undefined, undefined, false, params)
    }

    getCancelledExpiredBidsAndOffers(params) {
        return this.execute('get', '/PortfolioPerformance/CancelledExpiredBidsAndOffers', undefined, undefined, undefined, false, params)
    }

    getPortfolioPerformanceAllCaseSizes(wineId) {
        return this.execute('get', '/PortfolioPerformance/AllCasesByWineVintage?wineVintageId=' + wineId, undefined, undefined, undefined, false, null)
    }

    getBanners(languageId) {
        let selectedLangID = 1;
        switch (languageId) {
            case 'de':
                selectedLangID = 2;
                break;
            case 'es':
                selectedLangID = 3;
                break;
            case 'ru':
                selectedLangID = 4;
                break;
            case 'fr':
                selectedLangID = 7;
                break;
            case 'it':
                selectedLangID = 9;
                break;
        }
        return this.execute('get', '/Banner?lcid=' + selectedLangID, undefined, undefined, undefined, false, {})
    }
}

class SingleLock { }
