export class AAuth {
    static GET_TOKEN = 'requestGetToken';
    static GET_CONFIRMED = 'requestGetConfirmed';
    static SET_VERIFIER = 'setVerifier';
    static SET_VERIFIER_HASH = 'setVerifierHash';
    static SET_CODE = 'setCode';
    static SET_TOKEN = 'setToken';
    static REG_USER = 'regUser';
    static LOGIN_EXTERNAL_USER = 'loginExternalUser';
    static LOGIN_GOOGLE_USER = 'loginGoogleUser';
    static LOGIN_APPLE_USER = 'loginAppleUser';
    static SEND_MAIL = 'sendMail';
    static LOG_OUT = 'logOut';
}

export class AUser {
    static GET_BANK_ACCOUNTS = 'getBankAccounts';
    static GET_DECLARATIVE_DATA = 'getDeclarativeData';
    static UPDATE_DECLARATIVE_DATA = 'updateDeclarativeData';
    static UPLOAD_DOCUMENT_ONE = 'uploadDocumentOne';
    static UPLOAD_DOCUMENT_TWO = 'uploadDocumentTwo';
    static UPLOAD_DOCUMENT_SUBMIT = 'uploadDocumentSubmit';
    static GET_2FA_CONFIRMED = 'get2faConfirmed';
    static GET_2FA_EMAIL = 'get2faEmail';
    static SET_2FA_CODE = 'set2faCode';
    static WITHDRAW_MONEY = 'withdrawMoney';
    static GET_ADDRESSES = 'getAddresses';
    static ADD_ADDRESS = 'addAddress';
    static GET_PROMOCODES = 'getPromoCodes';
    static UPDATE_ADDRESS = 'updateAddress';
    static DELETE_ADDRESS = 'deleteAddress';
    static GET_USER_DATA = 'getUserData';
    static SAVE_USER_DATA = 'saveUserData';
    static GET_USER_COMMISSION = 'getUserCommission';
    static SET_SELECTED_MULTIPLE_INVOICES_FROM_INVOICE_CART = 'setSelectedMultipleInvoicesFromInvoiceCart';
    static CHANGE_PASS = 'changePassword';
    static CONFIRM_EMAIL = 'confirmEmail';
    static GET_INVOICE = 'getInvoice';
    static ADD_INVOICE = 'addInvoice';
    static GET_BANK_DETAILS = 'getBankDetails';
    static ADD_BANK_DETAILS = 'addBankDetails';
    static VALIDATE_BANK_DETAILS = 'validateBankDetails';
    static GET_INVOICE_PAYMENT = 'getInvoicePayment';
    static RESET_ADDRESSES = 'resetAddresses';
    static SET_USER_LOGGED = 'setUserLogged';
    static GET_INVOICES = 'getInvoices';
    static GET_INVOICE_DETAILS = 'getInvoiceDetails';
    static GET_INVOICE_PDF = 'getInvoicePdf';
    static RESET_BANK_DETAILS = 'resetBankDetails';
    static UPDATE_BANK_DETAILS = 'updateBankDetails';
    static GET_NOTIFICATIONS = 'getNotifications';
    static DELETE_NOTIFICATION = 'deleteNotification';
    static GET_NOTIFICATION_SETTINGS = 'getNotificationSettings';
    static SAVE_NOTIFICATION_SETTINGS = 'saveNotificationSettings';
    static SET_NOTIFICATIONS_READ = 'setNotificationsRead';
    static SET_SURVEY = 'setSurvey';
    static GET_VISITOR_COUNTRY = 'getVisitorCountry';
    static GET_KYC_STATUS = 'getKycStatus';
    static GET_WALLET_BALANCE = 'getWalletBalance';
    static GET_WALLET_TRANSACTIONS = 'getWalletTransactions';
}

export class AOrders {
    static GET_ORDERS = 'getOrders';
    static SAVE_ORDER = 'saveOrder';
    static CANCEL_ORDER = 'cancelOrder';
    static CREATE_ORDER = 'createOrder';
    static CREATE_MANGOPAY_CARD = 'createMangopayCard';
    static REGISTER_MANGOPAY_CARD = 'registerMangopayCard';
    static CHECK_REFERRAL_CODE = 'checkReferralCode';
    static SET_CURRENT_ORDER = 'setCurrentOrder';
    static SET_ACTIVE_ORDER = 'setActiveOrder';
    static SET_PAYPAL_SUCCESS = 'setPayPalSuccess';
    static SET_PAYPAL_SUCCESS_BID = 'setPayPalSuccessBid';
    static SET_PAYPAL_FAIL = 'setPayPalFail';
    static GET_TRADES = 'getTrades';
    static GET_HISTORY = 'getHistory';
    static CREATE_DELIVERY = 'createDelivery';
    static GET_COLLECTIONS = 'getCollections';
    static GET_TOP_COLLECTIONS = 'getTopCollections';
    static GET_COLLECTION_DETAILS = 'getCollectionDetails';
    static BUY_COLLECTION = 'buyCollection';
    static CHECK_PAYMENT_STATUS = 'checkPaymentStatus';
    static STRIPE_PROCESS_ACCOUNT = 'stripeProcessAccount'
    static CANCEL_PAYMENT = 'cancelPayment';
    static GET_SAVED_CC = 'getSavedCc';
    static GET_SAVED_MANGOPAY_CC = 'getSavedMangopayCc';
    static GET_INITIAL_TRADES = 'getInitialTrades';
    static GET_DELIVERIES = 'getDeliveries';
    static GET_DELIVERY_DETAILS = 'getDeliveryDetails';
    static GET_SALE_ORDERS = 'getSaleOrders';
    static GET_SALE_ORDER_DETAILS = 'getSaleOrderDetails';
    static GET_SALE_ORDER_PDF = 'getSaleOrderPdf';
    static GET_GIFT_FEES = 'getGiftFees';
    static ACTIVATE_GIFT = 'activateGift';
}

export class ACommon {
    static GET_ALL_COUNTRIES = 'getAllCountries';
    static GET_MOBILE_VERSION = 'getMobileVersion';
    static GET_ALL_REGIONS = 'getAllRegions';
    static GET_GIFT_COUNTRIES = 'getGiftCountries';
    static RESET_ALL_REGIONS = 'resetAllRegions';
    static GET_ALL_APPELLATIONS = 'getAllAppellations';
    static GET_ALL_APPELLATIONS_REGION = 'getAllAppellationsRegion';
    static GET_BANNERS = 'getBanners';
}

export class AMarket {
    static INIT_WINES = 'initWines';
    static GET_WINES = 'getMarketWines';
    static GET_TOP_WINES = 'getTopWines';
    static GET_TOP_EN_WINES = 'getTopEnWines';
    static GET_HOME_WINES = 'getHomeMarketWines';
    static REMOVE_WINES = 'removeMarketWines';
    static SET_ACTIVE_FILTER = 'setActiveFilter';
    static GET_PRODUCERS = 'getMarketProducers';
    static REMOVE_PRODUCERS = 'removeProducers';
    static LOAD_SORT_FILTERS = 'loadMarketSortFilters';
    static SET_PICKED_SORT_FILTERS = 'setMarketPickedSortFilters';
    static REMOVE_PICKED_SORT_FILTERS = 'removeMarketPickedSortFilters';
    static TOGGLE_SEARCH = 'marketToggleSearch';
}

export class AWines {
    static SELECT_WINE_ID = 'setSelectedWineId';
    static GET_WINE_DETAILS = 'getWineDetails';
    static GET_WINE_DETAILS_NOT_LOGGED = 'getWineDetailsNotLogged';
    static GET_WINE_CHART = 'getWineChart';
    static GET_FAVOURITE_WINES = 'getFavouriteWinesAction';
    static SWITCH_WINE_FAVOURITE = 'switchWineFavourite';
    static INIT_FAVS = 'initFavs';
    static CHANGE_FAV_POSITION = 'changeFavouritePosition';
    static GET_BARCODE_INFO = 'getBarcodeInfo';
    static SET_WINE_DETAILS_NULL = 'setWineDetailsNull';
}

export class APortfolio {
    static GET_PORTFOLIO = 'getPortfolio';
    static GET_PORTFOLIO_SUMMARY = 'getPortfolioSummary';
    static GET_NEW_PORTFOLIO_SUMMARY = 'getNewPortfolioSummary';
    static LOAD_WINES = 'portfolioLoadWines';
    static REMOVE_WINES = 'portfolioRemoveWines';
    static GET_SOLD_WINES_SUMMARY = 'getSoldWinesSummary';
    static GET_SOLD_WINES_LIST = 'getSoldWinesList';
    static GET_USER_SPECIFIC_TRANSACTIONS = 'getUserSpecificTransactions';
    static GET_CANCELLED_EXPIRED_BIDS_AND_OFFERS = 'getCancelledExpiredBidsAndOffers';
    static GET_PORTFOLIO_PERFORMANCE_ALL_CASE_SIZES = 'getPortfolioPerformanceAllCaseSizes';
}

export class APerformance {
    static GET_REGION_LIST = 'getRegionList';
    static LOAD_REGION_LIST = 'loadRegionList';
    static GET_COLOUR_LIST = 'getColourList';
    static LOAD_COLOUR_LIST = 'loadColourList';
    static GET_MARKET_VALUES = 'getMarketValues';
    static GET_MARKET_VALUES_BY_WINE = 'getMarketValuesByWine';
    static LOAD_MARKET_VALUES = 'loadMarketValues';
    static LOAD_MARKET_VALUES_BY_WINE = 'loadMarketValuesByWine';
    static GET_BOTTLES = 'getBottles';
    static LOAD_BOTTLES = 'loadBottles';
    static GET_INVESTMENTS_FILTERS = 'getInvestmentsFilters';
    static LOAD_INVESTMENTS_FILTERS = 'loadInvestmentsFilters';
    static GET_BIDSOFFERS_FILTERS = 'getBidsOffersFilters';
    static LOAD_BIDSOFFERS_FILTERS = 'loadBidsOffersFilters';
    static GET_SOLDWINES_FILTERS = 'getSoldWinesFilters';
    static LOAD_SOLDWINES_FILTERS = 'loadSoldWinesFilters';
}
