export class GAuth {
    static GET_VERIFIER = 'getVerifier';
    static GET_VERIFIER_HASH = 'getVerifierHash';
    static GET_TOKEN = 'getToken';
}

export class GUser {
    static GET_ADDRESSES = 'getAddresses';
    static GET_WALLET_TRANSACTIONS = 'getWalletTransactions';
    static GET_BANK_DETAILS = 'getBankDetails';
    static GET_INVOICE = 'getInvoice';
    static GET_USER_DATA = 'getUserData';
    static GET_DECLARATIVE_DATA = 'getDeclarativeData';
    static GET_WALLET_BALANCE = 'getWalletBalance';
    static GET_BANK_ACCOUNTS = 'getBankAccounts';
    static GET_KYC_STATUS = 'getKycStatus';
    static GET_USER_BUY_COMMISSION = 'getUserBuyCommission';
    static GET_USER_SELL_COMMISSION = 'getUserSellCommission';
    static GET_USER_MARKUP_COMMISSION = 'getUserMarkupCommission';
    static GET_USER_MAX_BUY_COMMISSION = 'getUserMaxBuyCommission';
    static GET_USER_MIN_BUY_COMMISSION = 'getUserMinBuyCommission';
    static GET_USER_MAX_SELL_COMMISSION = 'getUserMaxSellCommission';
    static GET_USER_MIN_SELL_COMMISSION = 'getUserMinSellCommission';
    static GET_USER_MIN_MARKUP_COMMISSION = 'getUserMinMarkupCommission';
    static GET_USER_MAX_MARKUP_COMMISSION = 'getUserMaxMarkupCommission';
    static GET_USER_HOLDINGS = 'getUserHoldings';
    static GET_USER_INSURANCE = 'getUserInsurance';
    static GET_USER_STORAGE = 'getUserStorage';
    static GET_USER_LOGGED = 'getUserLogged';
    static GET_USER_MIN_INSURANCE = 'getUserMinInsurance';
    static GET_INVOICES = 'getInvoices';
    static GET_INVOICE_DETAILS = 'getInvoiceDetails';
    static GET_INVOICES_LOADING = 'getInvoicesLoading'
    static GET_NOTIFICATIONS = 'getNotifications';
    static GET_NOTIFICATION_SETTINGS = 'getNotificationSettings';
    static GET_VISITOR_COUNTRY = 'getVisitorCountry';
    static GET_SELECTED_MULTIPLE_INVOICES_FROM_INVOICE_CART = 'getSelectedMultipleInvoicesFromInvoiceCart'
}

export class GOrders {
    static GET_BIDS_AND_OFFERS = 'getBidsAndOffers';
    static GET_ACTIVE_ORDER = 'getActiveOrder';
    static NEW_ORDER = 'getNewOrder';
    static GET_TRADES_HISTORY = 'getTradesHistory';
    static GET_ORDER_HISTORY = 'getOrderHistory';
    static GET_COLLECTIONS = 'getCollections';
    static GET_TOP_COLLECTIONS = 'getTopCollections';
    static GET_COLLECTIONS_LENGTH = 'getCollectionsLength';
    static GET_COLLECTION_DETAILS = 'getCollectionDetails';
    static GET_DELIVERIES = 'getDeliveries';
    static GET_DELIVERIES_LENGTH = 'getDeliveriesLength';
    static GET_DELIVERY_DETAILS = 'getDeliveryDetails';
    static GET_SALE_ORDERS = 'getSaleOrders';
    static GET_SALE_ORDERS_LENGTH = 'getSaleOrdersLength';
    static GET_SALE_ORDER_DETAILS = 'getSaleOrderDetails';
    static GET_CART_ITEMS = 'getCartItems';
    static GET_CART_ITEMS_TOTAL = 'getCartItemsTotal';
    static GET_GIFT_ITEMS = 'getGiftItems';
    static GET_GIFT_FEES = 'getGiftFees';
    static GET_GIFT_ITEMS_TOTAL = 'getGiftItemsTotal';
    static GET_GIFT_MESSAGE = 'getGiftMessage';
    static GET_SAVED_CC = 'getSavedCc';
    static GET_SAVED_MANGOPAY_CC = 'getSavedMangopayCc';
}

export class GCommon {
    static GET_COUNTRIES_LIST = 'getCountriesList';
    static GET_REGIONS_LIST = 'getRegionsList';
    static GET_GIFT_COUNTRIES = 'getGiftCountries';
    static GET_MOBILE_VERSION = 'getMobileVersion';
    static GET_APPELLATIONS_LIST = 'getAppellationsList';
    static GET_CURRENCY_SIGN = 'getCurrencySign';
    static GET_SHOULD_MARKET_UPDATES = 'getShouldMarketUpdates';
    static GET_BANNERS = 'getBanners';
}

export class GMarket {
    static GET_WINES = 'getWines';
    static GET_TOP_WINES = 'getTopWines';
    static GET_TOP_EN_WINES = 'getTopEnWines';
    static GET_ACTIVE_FILTER = 'getActiveFilter';
    static GET_TOTAL = 'getTotal';
    static GET_PRODUCERS = 'getProducers';
    static GET_SORT_FILTERS = 'getMarketSortFilters';
    static GET_PICKED_SORT_FILTERS = 'getMarketPickedSortFilters';
    static GET_OPEN_SEARCH = 'getMarketOpenSreach';
    static GET_FROM_MARKET = 'getMarketFromMarketh';
}

export class GWines {
    static GET_SELECTED_WINE_INFO = 'getBidOfferSelectedWineInfo';
    static GET_SELECTED_WINE_CASE = 'getSelectedWineCaseSize';
    static GET_SELECTED_WINE_PRICE = 'getSelectedWineCasePrice';
    static GET_SELECTED_WINE_UNITS = 'getSelectedWineCaseUnits';
    static GET_FAVOURITE_WINES = 'getFavouriteWinesGetter';
    static GET_FAVOURITE_WINES_LENGTH = 'getFavouriteWinesLength';
    static GET_IS_WINE_FAVOURITE = 'getIsWineFavourite';
    static GET_WINE_PORTFOLIO = 'getSelectedPortfolio';
    static IS_BUY_OFFERS_AVAILABLE = 'getIsBuyOffersAvailable';
    static IS_SELL_OFFERS_AVAILABLE = 'getIsSellOffersAvailable';
    static GET_WAREHOUSE_NAME = 'getWarehouseName';
    static GET_BARCODE_INFO = 'getBarcodeInfo';
    static GET_WINE_CHART = 'getWineChart';
}

export class GPortfolio {
    static GET_PORTFOLIO_WINE_LIST = 'getPortfolioWineList';
    static GET_PORTFOLIO_WINE_LIST_LENGTH = 'getPortfolioWineListLength';
    static GET_PORTFOLIO_WINE_LIST_LOADING = 'getPortfolioWineListLoading';
    static GET_PORTFOLIO_TOTAL_PROFIT = 'getPortfolioTotalProfit';
    static GET_PORTFOLIO_VALUE = 'getPortfolioValue';
    static GET_PORTFOLIO_SUMMARY = 'getPortfolioSummary';
    static GET_NEW_PORTFOLIO_SUMMARY = 'getNewPortfolioSummary';
    static GET_SOLD_WINES_SUMMARY = 'getSoldWinesSummary';
    static GET_SOLD_WINES_LIST = 'getSoldWinesList';
    static GET_USER_SPECIFIC_TRANSACTIONS = 'getUserSpecificTransactions';
    static GET_CANCELLED_EXPIRED_BIDS_AND_OFFERS = 'getCancelledExpiredBidsAndOffers';
    static GET_PORTFOLIO_PERFORMANCE_ALL_CASE_SIZES = 'getPortfolioPerformanceAllCaseSizes';
}

export class GPerformance {
    static GET_REGION_LIST = 'getRegionList';
    static GET_COLOUR_LIST = 'getColourList';
    static GET_MARKET_VALUES = 'getMarketValues';
    static GET_MARKET_VALUES_BY_WINE = 'getMarketValuesByWine';
    static GET_BOTTLES = 'getBottles';
    static GET_INVESTMENTS_FILTERS = 'getInvestmentsFilters';
    static GET_BIDSOFFERS_FILTERS = 'getBidsOffersFilters';
    static GET_SOLDWINES_FILTERS = 'getSoldWinesFilters';
}
