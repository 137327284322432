import BaseApiService from './api';

export default class PerformanceApiService extends BaseApiService {
    static _instance;
    static instance() {
        if (!this._instance)
            this._instance = new PerformanceApiService(new SingleLock());
        return this._instance;
    }

    constructor(lock) {
        super();
        if (lock instanceof SingleLock === false) {
            throw new Error('Sorry but this is singleton')
        }
    }

    getRegion(params) {
        return this.execute('get', '/PortfolioPerformance/MarketValueByRegion', undefined, undefined, undefined, false, params)
    }

    getColour(params) {
        return this.execute('get', '/PortfolioPerformance/Color', undefined, undefined, undefined, false, params)
    }

    getBottles(params) {
        return this.execute('get', '/PortfolioPerformance/Bottles', undefined, undefined, undefined, false, params)
    }

    getMarketValue(params) {
        return this.execute('get', '/PortfolioPerformance/TotalPerformance', undefined, undefined, undefined, false, params)
    }

    getMarketValueByWine(params) {
        return this.execute('get', '/PortfolioPerformance/MarketValueByWine', undefined, undefined, undefined, false, params)
    }

    getUserInvestmentsWineCountries(params) {
        return this.execute('get', '/PortfolioPerformanceFilters/GetUserInvestmentsWineCountries', undefined, undefined, undefined, false, params)
    }

    getUserInvestmentsWineColors(params) {
        return this.execute('get', '/PortfolioPerformanceFilters/GetUserInvestmentsWineColors', undefined, undefined, undefined, false, params)
    }

    getUserInvestmentsWineRegions(params) {
        return this.execute('get', '/PortfolioPerformanceFilters/GetUserInvestmentsWineRegions', undefined, undefined, undefined, false, params)
    }

    getUserInvestmentsWineAppellations(params) {
        return this.execute('get', '/PortfolioPerformanceFilters/GetUserInvestmentsWineAppellations', undefined, undefined, undefined, false, params)
    }

    getUserInvestmentsWineVintages(params) {
        return this.execute('get', '/PortfolioPerformanceFilters/GetUserInvestmentsWineVintages', undefined, undefined, undefined, false, params)
    }

    getUserInvestmentsProducersWineNames(params) {
        return this.execute('get', '/PortfolioPerformanceFilters/GetUserInvestmentsProducersWineNames', undefined, undefined, undefined, false, params)
    }

    getUserBidsAndOffersWineCountries() {
        return this.execute('get', '/PortfolioPerformanceFilters/GetUserBidsAndOffersWineCountries', undefined, undefined, undefined, false, {})
    }

    getUserBidsAndOffersWineColors() {
        return this.execute('get', '/PortfolioPerformanceFilters/GetUserBidsAndOffersWineColors', undefined, undefined, undefined, false, {})
    }

    getUserBidsAndOffersWineRegions() {
        return this.execute('get', '/PortfolioPerformanceFilters/GetUserBidsAndOffersWineRegions', undefined, undefined, undefined, false, {})
    }

    getUserBidsAndOffersWineAppellations() {
        return this.execute('get', '/PortfolioPerformanceFilters/GetUserBidsAndOffersWineAppellations', undefined, undefined, undefined, false, {})
    }

    getUserBidsAndOffersWineVintages() {
        return this.execute('get', '/PortfolioPerformanceFilters/GetUserBidsAndOffersWineVintages', undefined, undefined, undefined, false, {})
    }

    getUserBidsAndOffersProducersWineNames() {
        return this.execute('get', '/PortfolioPerformanceFilters/GetUserBidsAndOffersProducersWineNames', undefined, undefined, undefined, false, {})
    }

    getUserBidsAndOffersType() {
        return this.execute('get', '/PortfolioPerformanceFilters/GetUserBidsAndOffersType', undefined, undefined, undefined, false, {})
    }

    getUserBidsAndOffersTypeStatuses() {
        return this.execute('get', '/PortfolioPerformanceFilters/GetUserBidsAndOffersTypeStatuses', undefined, undefined, undefined, false, {})
    }

    getUserBidsAndOffersCaseSizes() {
        return this.execute('get', '/PortfolioPerformanceFilters/GetUserBidsAndOffersCaseSizes', undefined, undefined, undefined, false, {})
    }

    getUserSoldWinesCountries() {
        return this.execute('get', '/PortfolioPerformanceFilters/GetUserSoldWinesCountries', undefined, undefined, undefined, false, {})
    }

    getUserSoldWinesColors() {
        return this.execute('get', '/PortfolioPerformanceFilters/GetUserSoldWinesColors', undefined, undefined, undefined, false, {})
    }

    getUserSoldWinesRegions() {
        return this.execute('get', '/PortfolioPerformanceFilters/GetUserSoldWinesRegions', undefined, undefined, undefined, false, {})
    }

    getUserSoldWinesAppellations() {
        return this.execute('get', '/PortfolioPerformanceFilters/GetUserSoldWinesAppellations', undefined, undefined, undefined, false, {})
    }

    getUserSoldWinesVintages() {
        return this.execute('get', '/PortfolioPerformanceFilters/GetUserSoldWinesVintages', undefined, undefined, undefined, false, {})
    }

    getUserSoldWinesProducersWineNames() {
        return this.execute('get', '/PortfolioPerformanceFilters/GetUserSoldWinesProducersWineNames', undefined, undefined, undefined, false, {})
    }

}

class SingleLock { }
