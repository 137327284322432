import BaseApiService from './api'

export default class AuthApiService extends BaseApiService {

    static _instance;
    static instance() {
        if (!this._instance)
            this._instance = new AuthApiService(new SingleLock())
        return this._instance;
    }

    constructor(lock) {
        super();
        if (lock instanceof SingleLock === false) {
            throw new Error('Sorry but this is singleton')
        }
    }

    signup(data) {
        return this.execute('post', '/Registration', data, undefined, 'auth')
    }

    checkConfirmation(email) {
        return this.execute('get', '/EmailConfirmation/IsConfirmed?email=' + email, null, undefined, 'auth', true)
    }

    regExternal(data) {
        return this.execute('post', '/ExternalLogin/Facebook', data, undefined, 'auth')
    }

    regGoogle(data) {
        return this.execute('post', '/ExternalLogin/Google', data, undefined, 'auth')
    }

    regApple(data) {
        let _payload = {};
        _payload.code = data.code;
        _payload.firstName = data.firstName;
        _payload.lastName = data.lastName;

        return this.execute('post', '/ExternalLogin/Apple', _payload, undefined, 'auth')
    }

    signin(data) {
        return this.execute('post', '/token', data, '/connect', 'auth', true)
    }

    sendMail(emailAddress) {
        return this.execute('get', `/EmailConfirmation/resend?email=${emailAddress}`, null, undefined, 'auth', true)
    }
}

class SingleLock { }
