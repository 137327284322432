import BaseApiService from './api';
import axios from 'axios';

export default class UserApiService extends BaseApiService {
    static _instance;
    static instance() {
        if (!this._instance)
            this._instance = new UserApiService(new SingleLock());
        return this._instance;
    }

    constructor(lock) {
        super();
        if (!(lock instanceof SingleLock)) {
            throw new Error('Sorry but this is singleton');
        }
    }

    getUserCommission() {
        return this.execute('get', '/tradingSettings');
    }

    getUserData() {
        return this.execute('get', '/users');
    }

    getInvoice() {
        return this.execute('get', '/Invoice');
    }

    getDeclarativeData() {
        return this.execute('get', '/MangopayUser/GetOwnerData');
    }

    updateDeclarativeData(data) {
        return this.execute('post', '/MangopayUser/UpdateOwnerData', data);
    }

    uploadDocumentOne(data) {
        return this.execute('post', '/MangopayKyc/Initiate', data);
    }

    uploadDocumentTwo(data) {
        return this.execute('post', '/MangopayKyc/AddPage', data);
    }

    uploadDocumentSubmit() {
        return this.execute('get', '/MangopayKyc/Submit');
    }

    getKycStatus() {
        return this.execute('get', '/MangopayKyc/Status');
    }

    getBankAccounts() {
        return this.execute('get', '/MangopayBankAccount/Get');
    }

    getWalletBalance() {
        return this.execute('get', '/MangopayWallet/Balance');
    }

    getWalletTransactions(page) {
        return this.execute('get', '/MangopayWallet/Transactions?page=' + page);
    }

    getInvoiceDetails(data) {
        return this.execute('post', '/invoice/Details', data);
    }

    async getInvoicePDF(id, invoiceType, isGift, userId) {
        if (process.client) {
            let accessToken = await localStorage.getItem('access_token');
            let headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            };
            axios
                .get(
                    `https://api.vindome.net/api/invoice/Pdf?id=${id}&invoiceType=${invoiceType}`,
                    {
                        headers: headers,
                        responseType: 'blob'
                    }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                if (invoiceType === 1 || invoiceType === 5) {
                    link.setAttribute('download', (isGift ? `Invoice-${userId}-G-${id}.pdf` : (invoiceType === 5 ? `Invoice-${userId}-GDC23-${id}.pdf` : `Invoice-${userId}-${id}.pdf`)));
                } else {
                    link.setAttribute('download', `${userId}-SI-${id}.pdf`);
                }
                document.body.appendChild(link);
                link.click();
            });
        }
    }

    getBankDetails() {
        return this.execute('get', '/bankdetails');
    }

    get2FAConfirmed() {
        return this.execute('get', '/TwoFa/IsConfirmed');
    }

    get2FAEmail() {
        return this.execute('get', '/TwoFa/GenerateCode');
    }

    send2faCode(code) {
        return this.execute('post', '/TwoFa', code);
    }

    withdrawMoney(amount) {
        return this.execute('post', '/MangopayPayout/CreateInstantPayout', amount);
    }

    getNotifications() {
        return this.execute('get', '/notifications/get?from=0&to=100');
    }

    delNotification(data) {
        return this.execute('delete', '/Notifications/Delete', data);
    }

    getNotificationSettings() {
        return this.execute('get', '/Notifications/GetSettings');
    }

    saveNotificationSettings(data) {
        return this.execute('post', '/Notifications/SaveSettings', data);
    }

    resetNotifications() {
        return this.execute('post', '/Notifications/MarkAllRead', null);
    }

    saveAnswers (data) {
        return this.execute('post', '/Onboarding', data)
    }

    saveUserData(data) {
        return this.execute('put', '/users', data);
    }

    changePass(data) {
        return this.execute('post', '/Password', data);
    }

    addBankDetails(data) {
        return this.execute('post', '/bankdetails', data);
    }

    validateBankDetails(data) {
        return this.execute('post', '/BankDetails/Validate', data);
    }

    addAddress(data) {
        return this.execute('post', '/Address', data);
    }

    getPromos(params) {
        let _queryStr = '';

        params.collectionIds.forEach((el, index) => {
            _queryStr += '&collectionIds=' + el;
        });

        return this.execute('get', '/PromoCode/CalculatePromoCodeFeesAndPrices?promoCodeName=' + params.promoCodeName + '&isGift=' + params.isGift + _queryStr)
    }

    updateAddress(data) {
        return this.execute('put', '/Address', data);
    }

    confirmEmail(data) {
        return this.execute('post', '/EmailConfirmation', data, undefined, 'auth')
    }

    getInvoicePayment(data) {
        return this.execute('post', '/invoice/PayMonthlyInvoice', data);
    }
}

class SingleLock { }
