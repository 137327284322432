import BaseApiService from './api'
import axios from "axios";

export default class WineApiService extends BaseApiService {
    static _instance;

    static instance() {
        if (!this._instance)
            this._instance = new WineApiService(new SingleLock());
        return this._instance;
    }

    constructor(lock) {
        super();
        if (lock instanceof SingleLock === false) {
            throw new Error('Sorry but this is singleton')
        }
    }

    getWineDetails(wineObj) {
        let selectedLangID = 1;
        if (wineObj.languageId) {
            switch (wineObj.languageId) {
                case 'de':
                    selectedLangID = 2;
                    break;
                case 'es':
                    selectedLangID = 3;
                    break;
                case 'ru':
                    selectedLangID = 4;
                    break;
                case 'fr':
                    selectedLangID = 7;
                    break;
                case 'it':
                    selectedLangID = 9;
                    break;
            }
        }
        return this.execute('get', `/WineDetails?wineId=${wineObj.wineId}&caseSizeId=${wineObj.caseSize}&languageId=${selectedLangID}`)
    }

    getWinePerformanceForPeriod(wineVintageId) {
        return this.execute('get', `/WineDetails/GetWinePerformanceForPeriod?wineVintageId=${wineVintageId}`)
    }

    getWineDetailsNotLogged(wineObj) {
        let selectedLangID = 1;
        if (wineObj.languageId) {
            switch (wineObj.languageId) {
                case 'de':
                    selectedLangID = 2;
                    break;
                case 'es':
                    selectedLangID = 3;
                    break;
                case 'ru':
                    selectedLangID = 4;
                    break;
                case 'fr':
                    selectedLangID = 7;
                    break;
                case 'it':
                    selectedLangID = 9;
                    break;
            }
        }
        return this.execute('get', `/AnonymousWineDetails/${wineObj.wineId}?languageId=${selectedLangID}`)
    }

    getFavouriteWines({from, to}) {
        return this.execute('get', `/Favorites?from=${from}&to=${to}`);
    }

    getBarcodeInfo(id) {
        return this.execute('get', `/WineDetails/GetNfc?barcode=${id}`)
    }

    getWineDetailsChart(obj) {
        let params = {
            wineVintageId: obj.wineId,
            startDate: obj.startDate
        };

        return this.execute('get', `/PortfolioPerformance/MarketValueWDByWineVintage`, undefined, undefined, undefined, false, params)
    }

    setFavouriteWine(wine) {
        return this.execute('post', `/Favorites?wineId=${wine.wineId}&caseSizeId=${wine.caseSizeId}`);
    }

    updateFavouritePosition(wineId, position) {
        return this.execute('put', `/Favorites?wineId=${wineId}&position=${position}`)
    }

    deleteFavouriteWine(wine) {
        return this.execute('delete', `/Favorites?wineId=${wine.wineId}&caseSizeId=${wine.caseSizeId}`);
    }
}

class SingleLock { }
