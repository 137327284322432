<template>
    <v-app
        :class="{ 'lightmode': true, 'loginpage': $route.path.toLowerCase().indexOf('/login') > -1 || $route.path.toLowerCase().indexOf('/subscribe-en-primeur') > -1 || $route.path.toLowerCase().indexOf('/register') > -1 || $route.path.toLowerCase().indexOf('/forgot-password') > -1, 'collection-details-page': $route.path.toLowerCase().indexOf('collection-details') > -1, 'trading-desk': $route.path.toLowerCase().indexOf('trading-desk') > -1 || $route.path.toLowerCase().indexOf('paypal-') > -1 || $route.path.toLowerCase().indexOf('success-') > -1  || $route.path.toLowerCase().indexOf('wine-trading') > -1 || $route.path.toLowerCase().indexOf('wine-details') > -1 || $route.path.toLowerCase().indexOf('wine-performance') > -1 || $route.path.toLowerCase().indexOf('/my-account') > -1 || $route.path.toLowerCase().indexOf('/portfolio') > -1 || $route.path.toLowerCase().indexOf('/favourites') > -1 }">

        <app-navigation :class="{ 'payment-footer': $route.path === '/paypal-success' }"></app-navigation>

        <v-main transition="slide-x-transition">
            <router-view></router-view>
            <Footer :class="{ 'payment-footer': $route.path === '/paypal-success' }"/>
        </v-main>
    </v-app>
</template>

<script>
import AppNavigation from '../components/AppNavigation';
import Footer from '../components/Footer';

export default {
    name: 'App',
    metaInfo: {
        title: 'Vindome Smart Wine Investments | Fine Wine Trading App',
        titleTemplate: '%s | Vindome'
    },
    head() {
        const { path } = this.$route;
        let canonical = `https://vindome.net${path}`;

        return {
            htmlAttrs: {
                lang: this.$i18n.locale
            },
            link: [
                {rel: 'canonical', href: canonical}
            ],
            meta: [
                { "http-equiv": "content-language", content: this.$i18n.locale },
            ]
        }
    },
    components: {
        AppNavigation,
        Footer
    }
};
</script>

<style>
.v-sheet.v-app-bar.header-toolbar.v-toolbar:not(.v-sheet--outlined) {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.payment-footer {
    display: none !important;
}

.v-data-table > .v-data-table__wrapper > table {
    width: 100%;
    border-spacing: 2px !important;
    border-collapse: collapse !important;
}

.v-application .primary--text.v-label {
    color: var(--brand) !important;
    caret-color: var(--brand) !important;
}

.v-text-field > .v-input__control > .v-input__slot:after {
    border-color: rgba(0, 0, 0, .87) !important;
}

.v-text-field.error--text > .v-input__control > .v-input__slot:after {
    border-color: #ff5252 !important;
}

.trading-desk .theme--light.v-navigation-drawer {
    max-height: 100% !important;
    z-index: 100;
}

.theme--light.v-navigation-drawer.v-navigation-drawer--is-mobile {
    z-index: 999;
}

.router-link-active {
    font-weight: 700;
}

.sidebar-logo .v-image__image--cover {
    background-position: -6px -7px !important;
    background-size: initial;
}

.v-avatar {
    height: 50px !important;
    width: 50px !important;
    margin-bottom: 0 !important;
    border-radius: 0 !important;
}

.v-navigation-drawer__content,
.v-navigation-drawer--mini-variant {
    overflow-y: visible !important;
    overflow-x: visible !important;
}

.toggle-theme {
    position: fixed !important;
    bottom: 28px;
    width: 26px;
    height: 65px;
    margin-left: 17px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px !important;
    padding: 0 !important;
    display: block !important;
}

.white-theme {
    display: block;
    width: 26px;
    height: 32px;
    background: url('./assets/sprite-web.png') no-repeat -14px -363px;
}

.dark-theme {
    display: block !important;
    width: 26px;
    height: 32px;
    background: url('./assets/sprite-web.png') no-repeat -14px -403px;
}

.active-dark .dark-theme,
.active-light .white-theme {
    background-color: #E2C477;
    background-position-x: -57px;
    border-radius: 8px;
}

.theme--light.v-application.trading-desk {
    background: #ffffff;
    /*overflow: hidden;*/
}

.wine-guide .header-toolbar {
    box-shadow: none;
}

.wine-guide-country.two-line {
    padding-top: 10px;
    line-height: 20px !important;
}

.wine-guide-country.three-line {
    padding-top: 0;
    line-height: 20px !important;
}

.wine-guide-back {
    background: url('./assets/home/arrow.png') no-repeat top left;
    width: 15px;
    height: 17px;
    margin-right: 10px;
    display: inline-block;
    position: relative;
    top: 2px;
}

.wine-guide-back:hover {
    cursor: pointer;
}

.trading-desk .v-main {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.v-dialog .age-modal {
    padding: 16px;
    background: #FFF !important;
    border-radius: 16px;
}

.v-dialog .age-modal a,
.v-dialog .age-modal .link {
    text-decoration: underline;
    cursor: pointer;
}

.v-dialog .age-modal p {
    font-family: Montserrat, sans-serif;
    color: #000;
    margin: 0;
}

.v-dialog .age-modal .footer-text {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 12px;
}

.v-dialog .age-modal .v-card__title {
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    justify-content: center;
    text-align: center;
    word-break: break-word;
    padding-top: 0;
}

.age-modal__wrapper {
    border-radius: 16px;
}

.v-dialog > .v-card > .v-card__text.age-modal-body {
    border-radius: 8px;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    color: #000000;
}

.v-dialog .age-modal-body .cookie-txt {
    font-size: 16px;
}

.v-dialog .age-modal-body .age-checkbox {
    margin-top: 10px;
    color: #000000;
}
.v-dialog .age-modal-body .age-checkbox .v-label {
    color: #000000;
}
.v-dialog .age-modal-body .age-checkbox .v-messages {
    display: none;
}
.v-dialog .age-modal-body .btn-enter {
    margin-bottom: 16px;
    width: 100%;
    height: 44px;
    font-weight: 700;
}

.v-dialog .age-modal-body .btn-enter.v-btn--disabled {
    background: rgba(0, 0, 0, 0.38);
    border-color: rgba(0, 0, 0, 0.38);
}

.v-dialog .age-modal-body .btn-enter.v-btn--disabled span {
    color: #FFF;
}

.v-dialog .age-modal-body .age-label {
    margin-top: 10px;
    display: block;
}

.v-dialog .age-modal-body .v-input input::-webkit-outer-spin-button,
.v-dialog .age-modal-body .v-input input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.v-dialog .age-modal-body .v-input input[type=number] {
    -moz-appearance: textfield;
}

.v-dialog .age-modal-body .v-input .v-text-field__slot {
    height: 40px;
}

.v-dialog .age-modal-body .v-input .v-text-field__slot input {
    height: 40px;
    min-height: 41px;
}


@media (width: 1024px) {
    .wine-guide-right {
        width: calc(100% - 330px) !important;
    }
}
</style>
